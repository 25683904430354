import PropTypes from "prop-types";
import React from "react";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-700 bg-white font-body">
      <main>
        {children}
      </main>

      <footer className="w-full max-w-4xl px-4 py-8 mx-auto text-center border-t border-gray-200 md:px-8">
        <ul className="grid items-center justify-center grid-cols-2 gap-12 mb-4 overflow-hidden sm:grid-cols-3 md:flex">
          <li>
            <a href="https://www.focus-home.com" rel="noopener noreferrer nofollow" target="_blank">
              <img loading="lazy" alt="Focus Home Interactive" src="https://cdn.focus-home.com/fhi-fastforward-admin/resources/partners/focus-studio/images/29062020_90ec15f8061546ed89250a8c32af6eef.png" className="h-12 mx-auto" />
            </a>
          </li>
          <li>
            <a href="https://newworldinteractive.com/" rel="noopener noreferrer nofollow" target="_blank">
              <img loading="lazy" alt="New World Interactive" src="https://cdn.focus-home.com/fhi-fastforward-admin/resources/partners/new-world-interactive/images/18082020_9150e989526041b6b0a343a394cb92f7.png" className="h-12 mx-auto" />
            </a>
          </li>
        </ul>
        <p className="text-sm">© 2018 New World Interactive and Focus Home Interactive. Insurgency: Sandstorm is developed by New World Interactive and published by Focus Home Interactive. Insurgency: Sandstorm and its logo are trademarks or registered trademarks of New World Interactive. All other trademarks or registered trademarks belong to their respective owners. All rights reserved.</p>
        <nav className="flex flex-wrap justify-center mt-8 "><div className="px-5 py-2"><a href="https://focus-home.com/privacy-policy/" target="_blank" rel="noopener noreferrer nofollow" className="text-base leading-6 hover:underline">Privacy &amp; Cookie Policy</a></div><div className="px-5 py-2"><a href="https://focus-home.com/eula/" target="_blank" rel="noopener noreferrer nofollow" className="text-base leading-6 hover:underline">End user licence agreement</a></div><div className="px-5 py-2"><a href="https://focus-home.com/legal" target="_blank" rel="noopener noreferrer nofollow" className="text-base leading-6 hover:underline">Legal Information</a></div><div className="px-5 py-2"><a href="https://support.newworldinteractive.com" target="_blank" rel="noopener noreferrer nofollow" className="text-base leading-6 hover:underline">Support</a></div></nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
