import React, { useRef, useState } from "react";
import { useMutation, gql } from '@apollo/client';

import Layout from "../components/layout";
import SEO from "../components/seo";
import NDA from "./../components/NDA"
import Banner from "./../images/iss-banner.jpeg";
import logo from "./../images/iss-logo.png";

const platforms = [
  {
    name: 'PS4',
    id: 'ps4'
  },
  {
    name: 'PS5',
    id: 'ps5'
  },
  {
    name: 'Xbox One',
    id: 'xone'
  },
  {
    name: 'Xbox Series S',
    id: 'xss'
  },
  {
    name: 'Xbox Series X',
    id: 'xsx'
  }
]

function IndexPage() {
  const [submitted, setSubmitted] = useState(false)
  const [form, setForm] = useState({
    email: '',
    region: 'europe',
    discord: '',
    platforms: '',
    newsletter: false
  });
  const [checkedState, setCheckedState] = useState(
    new Array(platforms.length).fill(false)
  );
  const [NDAaccepted, setNDAaccepted] = useState(false)
  const [openedModal, setOpenedModal] = useState(false)
  const [errorDiscord, setErrorDiscord] = useState("")
  const [error, setError] = useState("")
  const [createSubscriber, { loading }] = useMutation(CREATE_SUBSCRIBER);
  const NDARef = useRef();

  const ndaChange = () => {
    setNDAaccepted(NDARef.current.checked)
  }

  const openModal = value => {
    setOpenedModal(value);
  }

  const updateField = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleCheckboxOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    const total = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return [...sum, platforms[index].id];
        }
        return sum;
      },
      ''
    );

    setForm({
      ...form,
      platforms: total
    });
  };

  const checkDiscordName = e => {
    const value = e.target.value;
    if (value && !value.match('^.{3,32}#[0-9]{4}$')) {
      setErrorDiscord(true)
    } else {
      setErrorDiscord(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (errorDiscord) {
      setError({ message: 'Your Discord tag is required' });
      return;
    } else {
      setError('');
    }

    try {
      await createSubscriber({
        variables: form
      });
      setSubmitted(true)
    } catch (er) {
      setError(er);
    }
  }

  return (
    <Layout>
      <SEO
        title="Beta"
      />
      <div className={`${openedModal ? 'bg-opacity-50' : 'bg-opacity-0 pointer-events-none'} fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full py-20 bg-black `}>
        <div className="absolute top-0 left-0 w-full h-full" onClick={() => openModal(false)}></div>
        {openedModal && <div className="z-20 w-full max-w-4xl max-h-full px-6 overflow-y-scroll text-gray-900 bg-white md:px-16">
          <div className="py-8 md:py-16">
            <NDA />
          </div>
          <button type="button" onClick={() => {
            openModal(false);
            setNDAaccepted(true)
          }}
            className="inline-flex items-center justify-center w-full px-6 py-3 mb-4 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 border-2 border-white hover:bg-indigo-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">Accept</button>
        </div>
        }
      </div>
      <section className="pt-8 lg:pt-0">
        <div className="relative">
          <img className="p-6 mx-auto md:max-w-md lg:max-w-xl xl:max-w-2xl md:absolute md:z-20 md:transform md:-translate-y-1/2 md:top-1/2 md:-translate-x-1/3 md:left-1/3" alt="Insurgency: Sandstorm" src={logo} />
          <div className="relative mt-4 lg:mt-0">
            <div className="absolute top-0 left-0 z-10 w-full h-8 lg:hidden bg-gradient-to-b from-white"></div>
            <div className="absolute bottom-0 left-0 z-10 w-full h-24 bg-gradient-to-t from-white"></div>
            <img className="object-cover object-right w-full h-64 md:h-auto" alt="" src={Banner} />
          </div>
        </div>
        {!submitted && (
          <>
            <h1 className="relative z-20 px-4 mt-4 text-2xl font-semibold text-center text-gray-900 uppercase sm:text-4xl sm:px-0 font-display">We need you!</h1>
            <div className="max-w-2xl px-4 mx-auto mt-6 sm:px-0">
              <p className="mt-2">Sign up for a chance to be one of the first to play Insurgency: Sandstorm on consoles in our Closed Beta. With your help and feedback, we will make the game the best it can be!</p>
              <p className="mt-2">To increase your chances to be selected for the Beta, make sure to fill the “Discord nickname” field down below.</p>
              <p className="mt-2">Please keep in mind that if you are invited to the Beta you will need to sign an NDA and be unable to discuss the contents of the Beta with anyone outside of it.</p>
              <p className="mt-6">Requirements :</p>
              <ul>
                <li>- To be 18 years old or older;</li>
                <li>- To have a console (PS5, Xbox Series S|X, PS4 or Xbox One);</li>
                <li>- To speak English to provide feedback to the development team.</li>
              </ul>
              <div className="mt-6">
                <p className="font-medium">Before you continue with the registration process, you must read and accept <button type="button" className="inline-flex underline" onClick={() => openModal(true)}>the Non-Disclosure Agreement</button> governing your access  to the console Closed Beta of &quot;Insurgency: Sandstorm&quot;.</p>
                <div className="flex items-start mt-4">
                  <input id="NDA" checked={NDAaccepted} required name="NDA" type="checkbox" ref={NDARef} onChange={ndaChange} className="w-4 h-4 mt-1 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                  <label htmlFor="NDA" className="ml-3 text-sm font-medium text-gray-700">I understand and accept the terms of <button type="button" className="inline-flex underline" onClick={() => openModal(true)}>the Non-Disclosure Agreement</button> related to the closed beta of &quot;Insurgency Sandstorm&quot;.</label>
                </div>
              </div>
            </div>
          </>)}
        {submitted && (
          <>
            <h1 className="relative z-20 px-4 text-2xl font-semibold text-center uppercase sm:text-3xl sm:px-0">Thanks for your registration!</h1>
            <p className="max-w-2xl px-4 mx-auto mt-6 sm:px-0">
              We&apos;ll send you an email in the coming weeks if you&apos;re selected. Don&apos;t worry if you don&apos;t, as we&apos;ll run several Betas.</p>
          </>)}
      </section>
      <section className="w-full max-w-2xl px-4 py-8 mx-auto md:py-16">
        {!submitted && NDAaccepted &&
          <>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 sm:gap-y-8">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-5">Email*</label>
                <div className="relative mt-1 shadow-sm">
                  <input required id="email" name="email" type="email" onChange={updateField} value={form.email} className="block w-full px-4 py-3 text-gray-700 transition duration-150 ease-in-out border-gray-300 rounded-none form-input" />
                </div>
              </div>
              <div>
                <label htmlFor="discord" className="flex text-sm font-medium leading-5">Discord nickname
                  <span className="relative group">
                    <svg className="w-5 h-5 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div className="absolute left-0 z-10 w-48 px-3 py-1 mt-2 text-xs leading-5 text-white transition duration-75 ease-in origin-top-left transform scale-95 bg-gray-800 border border-red-500 shadow-md opacity-0 pointer-events-none group-hover:opacity-100 group-hover:scale-100">
                      Don&apos;t forget your Discord tag (nickname#1234).
                    </div>
                  </span>
                </label>
                <div className="relative mt-1 shadow-sm">
                  <input id="discord" name="discord" type="text" onChange={updateField} onBlur={checkDiscordName} value={form.discord} className="block w-full px-4 py-3 text-gray-700 transition duration-150 ease-in-out border-gray-300 rounded-none form-input" />
                </div>
                {errorDiscord && (<p className="inline-block mt-1 text-sm text-red-600">Your Discord tag is required (nickname#1234)</p>)}
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="region" className="block text-sm font-medium leading-5">Region*</label>
                <select id="region" name="region" onChange={updateField} value={form.region} className="block w-full py-3 pl-4 pr-10 mt-1 text-base leading-6 text-gray-700 border-gray-300 rounded-none form-select focus:outline-none focus:shadow-outline-indigo sm:text-sm sm:leading-5">
                  <option value="africa">Africa</option>
                  <option value="asia">Asia</option>
                  <option value="europe">Europe</option>
                  <option value="oceania">Oceania</option>
                  <option value="russia">Russia</option>
                  <option value="south_america">South America</option>
                  <option value="us_east">US East</option>
                  <option value="us_west">US West</option>
                </select>
              </div>

              <div>
                <label htmlFor="platform" className="block text-sm font-medium leading-5">Platforms*</label>
                <div className="mt-4 space-y-4">
                  {platforms.map(({ name, id }, index) => {
                    return (
                      <div key={index}>
                        <div className="flex items-center h-5">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={name}
                            value={id}
                            checked={checkedState[index]}
                            onChange={() => handleCheckboxOnChange(index)}
                            className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                          />
                          <label htmlFor={`custom-checkbox-${index}`} className="ml-3 text-sm font-medium text-gray-700">{name}</label>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-2 sm:col-span-2">
                <div className="flex items-start">
                  <input id="legals" required name="legals" type="checkbox" className="w-4 h-4 mt-1 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                  <label htmlFor="legals" className="ml-3 text-sm font-medium text-gray-700">* I certify that I am 18 years old or more. If under 18, I understand that I am not allowed to register to this Closed Beta</label>
                </div>
                <div className="flex items-start mt-2">
                  <input id="newsletter" value={form.newsletter} onClick={() => {
                    setForm({
                      ...form,
                      newsletter: !form.newsletter
                    })
                  }}
                    name="newsletter" type="checkbox" className="w-4 h-4 mt-1 text-indigo-600 border-gray-300 focus:ring-indigo-500" />
                  <label htmlFor="newsletter" className="ml-3 text-sm font-medium text-gray-700">I hereby agree to subscribe to Focus Home Interactive newsletter and to receive promotional offers and information from Focus Home Interactive</label>
                </div>
              </div>
              {error && <p className="text-red-500 sm:col-span-2">{error?.message}</p>}

              <div className="sm:col-span-2">
                <button type="submit" className="inline-flex justify-center w-full px-6 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-50">
                  {loading ? (
                    <>
                      <svg className="w-5 h-5 mr-3 -ml-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Loading</span>
                    </>) : (
                    <span>Submit</span>
                  )}
                </button>
              </div>
            </form>
            <p className="mt-8 text-xs">
              You must file the fields with an asterisk to get a chance to participate. Focus Home Interactive acts as data controller for the collection and the processing of your email address for the sole purposes of subscription, registration, functioning of the closed Alpha test and/or any subsequent tests and analyzing the performance and quality of the under development game. Focus Home Interactive may share your email address with New World Interactive which will process your personal information as an independent data controller. You have rights of access to and rectification or erasure of your personal data and to restrict or object to its processing, as well as the right to data portability, which you can exercise at any time by making a written request to the following address: <a className="underline" href="mailto:personaldata@focus-home.com">personaldata@focus-home.com</a>.  The processing of your data is based on your consent which you can withdraw at any time by sending us an email.  If after contacting us you deem that your rights have not been fully respected, you also have the right to lodge a complaint about our processing of your personal data with the competent supervisory authority, such as the French Data Protection Authority (Commission Nationale de l’Informatique et des Libertés). To learn more about the processing of your personal data, please refer to our <a href="https://focus-home.com/privacy-policy/" target="_blank" rel="noopener noreferrer nofollow" className="underline" >personal data protection general policy</a>.
            </p>
          </>
        }
      </section>
    </Layout >
  );
}

const CREATE_SUBSCRIBER = gql`
            mutation createBetaSubscriberMutation($email: String!, $platforms: JSON!, $region: Region!, $discord: String, $newsletter: Boolean) {
              createISSBetaSubscriber(email: $email, platforms: $platforms, region: $region, discord: $discord, newsletter: $newsletter) {
              email
            }
  }
            `;


export default IndexPage;
